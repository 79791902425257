@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

@font-face {
    font-family: 'IQOS-Light';
    src: url('./../fonts/IQOS-Light.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family: 'IQOS-Bold';
    src: url('./../fonts/IQOS-Bold.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family: 'IQOS-Regular';
    src: url('./../fonts/IQOS-Regular.otf') format('opentype');
    font-style: normal;
}
