@import 'assets/scss/variables';

.root {
  padding: 0 8px;
  width: 100%;
  max-width: 1400px;
}

.option {
  width: 100%;
  max-width: $mobile-breakpoint;
  height: 92px;
  margin: 0 auto #{$default-spacing-unit * 2} auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 55px;
}

.wrapper {
  position: relative;
  max-width: 1013px;
  background-color: #F6F4F0;
  margin-left: auto;
  flex-grow: 1;
}

.heroWrapper {
  position: absolute;
  left: 0px;
  width: 604px;
  z-index: 22;

  img {
    max-width: 100%;
  }
}

.container {
  padding: 46px 82px;
}

.containerForm {
  max-width: 637px;
  margin-left: auto;
}

.actionWrapper {
  margin-top: 55px;
}

@media only screen and (max-width: 1380px) {
  .heroWrapper {
    width: 480px;
  }
}

@media only screen and (max-width: 1260px) {
  .heroWrapper {
    display: none;
  }

  .containerForm, .wrapper {
    margin: 0 auto;
  }
}

@media only screen and (max-width: $mobile-register-breakpoint) {
  .actionWrapper {
    text-align: center;
    margin-bottom: 22px;
    margin-top: 55px;
  }
  .content {
    margin-bottom: 34px;
  }
  .container {
    padding: 24px 0;
  }

  .containerForm {
    margin: 0 26px;
  }
}

