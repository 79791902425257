@import '../../assets/scss/variables';

.svg {
    margin-bottom: #{$default-spacing-unit * 5};
    text-align: center;
}

.description {
    margin-bottom: #{$default-spacing-unit * 4};
}

.link {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
        color: $primary-color;
    }

    svg {
        margin-left: #{$default-spacing-unit};
        transform: rotate(180deg);
    }
}

.noParent {
    box-sizing: border-box !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 282px;
    margin: auto;
}

.sms {
    color: $primary-color;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0;

    &:hover {
        cursor: pointer;
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .customContainerMobile {
        padding: #{$default-spacing-unit * 2};
    }

    .title {
        margin-bottom: #{$default-spacing-unit * 4};
    }
    .description {
        margin-bottom: #{$default-spacing-unit * 4 + $default-spacing-unit * 0.5};
    }
}
