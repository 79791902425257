@import 'assets/scss/variables';

.root {
  margin-top: 40px;
  h3 {
    font-size: 20px;
    color: #34303D;
  }
}

// .sliderWrapper {
//   // padding-right: 16px;
//   // width: calc(100% - 40px);
// }

.helpers {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.helpersSide p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #34303D;
  opacity: 1;
}

@media only screen and (max-width: $mobile-register-breakpoint) {
  .root {
    h3 {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }
}