$white-color: #fff;
$black-color: #000;
$primary-color: #00d1d2;
$secondary-color: #34303d;
$primary-background-color: #FFFDFB;
$custom-orange-color: #d4712d;
$default-spacing-unit: 8px;

$mobile-breakpoint: 432px;
$mobile-register-breakpoint: 708px;
