@import 'assets/scss/variables';

.root {
  padding-top: 24px;
  &Small {
    max-width: 420px;
    margin: 0 auto;
  }

  h3 {
    font-size: 20px;
    color: #34303D;
  }
}

.raitingList {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

.raitingListItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  // border: '1px solid red',
  box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.16);
  cursor: pointer;

  &__selected {
    background-color: #00D1D2;
  }
}

.helpers {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

.helpersSide {
  p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #34303D;
    opacity: 1;
  }

  &__right {
    text-align: right;
  }
}


@media only screen and (max-width: $mobile-register-breakpoint) {
  .root {
    h3 {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }

  .helpersSide {
    max-width: 110px;
  }

  .raitingListItem {
    width: 28px;
    height: 28px;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .helpersSide {
    max-width: 100px;
  }
  .helpersSide p {
    font-size: 12px;
  }
}
