// @import-normalize;
@import './variables.scss';
@import './fonts.scss';
@import './overrides.scss';
@import './snackbars.scss';

html,
body,
* {
    margin: 0;
    padding: 0;
    font-family: 'IQOS-Reqular';
    box-sizing: border-box;
}

h2, h3 {
  font-family: 'IQOS-Bold';
}

body {
    background-color: $primary-background-color;
    height: 100vh;
    #root {
        height: 100vh;
    }
}

.container {
    background-color: $white-color;
    padding: #{$default-spacing-unit * 5};
    box-sizing: content-box;
    max-width: 352px;

    @media only screen and (max-width: $mobile-breakpoint) {
        padding: #{$default-spacing-unit * 3} #{$default-spacing-unit * 2} #{$default-spacing-unit * 2};
        max-width: 310px;
    }
}

.MuiCollapse-root {
    width: $mobile-breakpoint;
}

.SnackbarItem-contentRoot {
    // shit happens...
    padding: 24px 40px 20px !important;
    box-shadow: none !important;
}

@media only screen and (max-width: $mobile-breakpoint) {
    .MuiCollapse-root {
        width: 310px;
    }
    .MuiDialog-paper.MuiPaper-root {
        margin: #{$default-spacing-unit * 2};
    }
    .MuiDialogContent-root {
        // shit happens...
        padding-left: #{$default-spacing-unit * 2 + $default-spacing-unit * 0.5} !important;
        padding-right: #{$default-spacing-unit * 2 + $default-spacing-unit * 0.5} !important;
    }
}
