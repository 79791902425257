@import 'assets/scss/variables';

.root {
  margin-top: 24px;
  h3 {
    font-size: 20px;
    text-align: center;
    color: #34303D;
  }
}

.wrapper {
  margin-top: 24px;
}


@media only screen and (max-width: $mobile-register-breakpoint) {
  .root {
    h3 {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
