.MuiCollapse-root {
    width: $mobile-breakpoint;
}

.SnackbarItem-contentRoot {
    // shit happens...
    padding: 24px 40px 20px !important;
    box-shadow: none !important;
}

@media only screen and (max-width: $mobile-breakpoint) {
    .SnackbarContainer-root {
        width: auto !important;
    }
    .MuiCollapse-root {
        width: 310px;
    }
    .MuiDialog-paper.MuiPaper-root {
        margin: #{$default-spacing-unit * 2};
    }
    .MuiDialogContent-root {
        // shit happens...
        padding-left: #{$default-spacing-unit * 2 + $default-spacing-unit * 0.5} !important;
        padding-right: #{$default-spacing-unit * 2 + $default-spacing-unit * 0.5} !important;
    }
}

.custom-snackbar-container {
    &.server-error {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $primary-color;

        &:hover {
            cursor: pointer;
            color: $primary-color;
        }

        svg {
            margin-left: #{$default-spacing-unit};
            transform: rotate(180deg);
        }
    }
}
